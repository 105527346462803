import { ContentPanel } from '@/components/shared/ContentPanel';
import React from 'react';
import { Button } from '@/components/shared/Button';
import Select from 'react-select';
import { PhotographerRank } from '../../types';
import styles from './index.module.scss';

export type Props = {
  ranks?: PhotographerRank[];
  doSelect: (rank?: PhotographerRank) => void;
};

export const SearchPanel = React.memo<Props>(function SearchPanel({
  ranks = [],
  doSelect,
}) {
  const [selectedRank, setSelectedRank] = React.useState<PhotographerRank>();
  const options = [{ value: -1, label: 'なし以外' }].concat(
    ranks.map((rank) => ({
      value: rank.id,
      label: rank.name,
    }))
  );

  return (
    <ContentPanel title="検索">
      <Select
        className={styles.select}
        defaultValue={options[0]}
        name="color"
        options={options}
        onChange={(selectedOption) => {
          setSelectedRank(
            ranks.find((rank) => rank.id === selectedOption?.value)
          );
        }}
      />
      <div className={styles.control}>
        <Button
          value="検索"
          type="submit"
          variant="search"
          onClick={() => doSelect(selectedRank)}
        />
      </div>
    </ContentPanel>
  );
});
