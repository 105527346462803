import { useAsync, useAsyncFn } from 'react-use';
import { FeedbackTarget } from './types';
import { fetcher } from '@/ts/fetch';
import { isValidationError, toMessages } from '@/ts/useApi';
import { useCallback, useEffect, useState } from 'react';
import { flattenValidatorMessages } from '@/components/shared/Form/Errors';
import { Rank, useRanks } from '@/ts/useRanks';
import { useHistory, useLocation } from 'react-router-dom';

type UseFeedbackTargetsReturn = {
  loading: boolean;
  errorMessages?: string[];
  results?: FeedbackTarget[];
  ranks?: Rank[];
  handleRankSelect: (rank?: Rank) => void;
};

export type FeedbackTargetsAPIResponse = {
  success: boolean;
  data: FeedbackTarget[];
};

export const useFeedbackTargets = (): UseFeedbackTargetsReturn => {
  const [errorMessages, setErrorMessages] = useState<string[] | undefined>();
  const [feedbackTargets, setFeedbackTargets] = useState<FeedbackTarget[]>();
  const { search: queryString } = useLocation();
  const history = useHistory();
  const {
    loading: rankLoading,
    errorMessages: rankAPIErrorMessages,
    results: ranks,
  } = useRanks();

  const [{ loading, value: results }, handleGetFeedbackTargets] =
    useAsyncFn(async () => {
      try {
        const response = await fetcher<FeedbackTargetsAPIResponse>(
          '/api/photographers/feedback_targets'
        );
        if (!response.success) {
          throw new Error('Failed to fetch data');
        }

        return response.data;
      } catch (e: unknown) {
        if (isValidationError(e) && e.jsonMessage.validator.messages) {
          setErrorMessages(
            flattenValidatorMessages(e.jsonMessage.validator.messages).map(
              (message) => message
            )
          );
        } else {
          setErrorMessages(toMessages(e));
        }
      }
    }, []);

  useAsync(async () => {
    await handleGetFeedbackTargets();
  }, [handleGetFeedbackTargets]);

  useEffect(() => {
    setErrorMessages(rankAPIErrorMessages);
  }, [rankAPIErrorMessages]);

  const handleRankSelect = useCallback(
    (rank?: Rank) => {
      if (!rank) {
        history.push('/photographers/feedback_targets');
        return;
      }

      history.push(`/photographers/feedback_targets?rankId=${rank.id}`);
    },
    [history]
  );

  useEffect(() => {
    if (!results) return;

    const params = new URLSearchParams(queryString);
    const rankId = parseInt(params.get('rankId') || '-1', 10);

    setFeedbackTargets(
      rankId === -1
        ? results.filter((feedbackTarget) => feedbackTarget.rankId !== 0)
        : results.filter((feedbackTarget) => feedbackTarget.rankId === rankId)
    );
  }, [queryString, results]);

  return {
    loading: loading || rankLoading,
    errorMessages,
    results: feedbackTargets,
    ranks,
    handleRankSelect,
  };
};
