import React from 'react';
import SvgLoading from '@/components/shared/Loading/SvgLoading';
import { PageContainer } from '@/components/shared/PageContainer';
import { ResultPanel } from './components/ResultPanel';
import { useFeedbackTargets } from './hooks';
import { ErrorPanel } from '@/components/shared/Form/Errors';
import { SearchPanel } from './components/SearchPanel';

export const FeedbackTargets = React.memo(function FeedbackTargets() {
  const { ranks, loading, results, errorMessages, handleRankSelect } =
    useFeedbackTargets();

  if (loading) return <SvgLoading />;

  return (
    <PageContainer title="カメラマンフィードバック対象一覧">
      {<ErrorPanel messages={errorMessages} />}
      <SearchPanel ranks={ranks} doSelect={handleRankSelect} />
      <ResultPanel feedbackTargets={results} />
    </PageContainer>
  );
});
