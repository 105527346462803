import React from 'react';
import { FeedbackTarget } from '../../types';
import { ContentPanel } from '@/components/shared/ContentPanel';
import { Table, TableRow } from '@/components/shared/Table';
import { format } from 'date-fns';
import { kanriUrl } from '@/ts/url';
import { Link } from 'react-router-dom';

type Props = {
  feedbackTargets?: FeedbackTarget[];
};

const columns = [
  'カメラマンID',
  '氏名',
  'ランク',
  'イベント確認用',
  'フィードバック',
  'メール送信日時',
];
const columnsParam = [
  '200px',
  '200px',
  '200px',
  'minmax(400px, 1fr)',
  '200px',
  '200px',
];

const makeLink = (
  feedbackTarget: FeedbackTarget
): string | React.ReactElement => {
  if (feedbackTarget.rankId !== 1) {
    return (
      <a
        className="c-textlink"
        href={kanriUrl({
          action_owner_SHINSEItehailist2: 'true',
          photographer_id: String(feedbackTarget.id),
          photographingday_to: format(new Date(), 'yyyy/MM/dd'),
        })}
      >
        過去分手配リスト
      </a>
    );
  } else if (feedbackTarget.eventNo > 0 && feedbackTarget.eventName) {
    return (
      <Link className="c-textlink" to={`/events/${feedbackTarget.eventNo}`}>
        {feedbackTarget.eventName}
      </Link>
    );
  }

  return '';
};
const makeFeedbackLink = (
  feedbackTarget: FeedbackTarget
): string | React.ReactElement => {
  if (feedbackTarget.feedbackId) {
    return (
      <div>
        <div>
          <a
            className="c-textlink"
            href={kanriUrl({
              action_owner_PHOTOGRAPHERFEEDBACKedit: 'true',
              id: String(feedbackTarget.feedbackId),
            })}
          >
            フィードバック入力
          </a>
        </div>
        {!feedbackTarget.mailSendDate && <span>(入力有)</span>}
      </div>
    );
  } else {
    return (
      <a
        className="c-textlink"
        href={kanriUrl({
          action_owner_PHOTOGRAPHERFEEDBACKnew: 'true',
          photographer_id: String(feedbackTarget.id),
          eventno: feedbackTarget.eventNo ? String(feedbackTarget.eventNo) : '',
        })}
      >
        フィードバック入力
      </a>
    );
  }
};

export const ResultPanel = React.memo<Props>(function ResultPanel({
  feedbackTargets,
}) {
  if (!feedbackTargets || feedbackTargets?.length === 0) {
    return null;
  }

  return (
    <ContentPanel marginTop="large">
      <Table
        columns={columns}
        rows={
          feedbackTargets.map(
            (feedbackTarget): TableRow => [
              `${feedbackTarget.id}`,
              `${feedbackTarget.lastName} ${feedbackTarget.firstName}`,
              feedbackTarget.rankName,
              makeLink(feedbackTarget),
              makeFeedbackLink(feedbackTarget),
              feedbackTarget.mailSendDate
                ? format(
                    new Date(feedbackTarget.mailSendDate),
                    'yyyy/MM/dd HH:mm:ss'
                  )
                : '',
            ]
          ) || []
        }
        gridTemplateColumns={columnsParam}
      />
    </ContentPanel>
  );
});
