import { useState } from 'react';
import { useAsync, useAsyncFn } from 'react-use';
import { fetcher } from './fetch';
import { isValidationError, toMessages } from './useApi';
import { flattenValidatorMessages } from '@/components/shared/Form/Errors';

export type Rank = {
  id: number;
  name: string;
};

type RanksAPIResponse = {
  success: boolean;
  data: Rank[];
};

type UseFRanksReturn = {
  loading: boolean;
  errorMessages?: string[];
  results?: Rank[];
};

export const useRanks = (): UseFRanksReturn => {
  const [errorMessages, setErrorMessages] = useState<string[] | undefined>();

  const [{ loading, value: results }, handleGetRanks] = useAsyncFn(async () => {
    try {
      const response = await fetcher<RanksAPIResponse>(
        '/api/photographers/ranks'
      );
      if (!response.success) {
        throw new Error('Failed to fetch data');
      }

      return response.data;
    } catch (e: unknown) {
      if (isValidationError(e) && e.jsonMessage.validator.messages) {
        setErrorMessages(
          flattenValidatorMessages(e.jsonMessage.validator.messages).map(
            (message) => message
          )
        );
      } else {
        setErrorMessages(toMessages(e));
      }
    }
  }, []);

  useAsync(async () => {
    await handleGetRanks();
  }, [handleGetRanks]);

  return { loading, results, errorMessages };
};
